import { createRouter, createWebHistory } from 'vue-router'
import { routes as routes } from '../app/index'

const router = createRouter({
    mode: 'hash',
    history: createWebHistory(),
    routes,
})

const otherRoutes = [
    // {
    //     path: '/',
    //     name: 'sign-in',
    //     component: () => import('../app/auth/components/Login.vue')
    // },
]
    // for (let i = 0; i < otherRoutes.length; i++){
    //     routes.push(otherRoutes[i]);
    // }
export default router;