import home from './home/routes'
import myProfile from './myProfile/routes'
import employeeList from './employeeList/routes'
import appraisalCriteria from './appraisalCriteria/routes'
import appraisalList from './appraisalList/routes'
import auth from './auth/routes'

export default [...home, ...myProfile, ...employeeList, ...appraisalCriteria, ...appraisalList, ...auth];

 
