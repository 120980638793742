import { createStore } from 'vuex'
import state from "./state.js";
import getters from "./getters.js";
import mutations from "./mutations.js";
import actions from "./actions.js";

import home from "../app/home/vuex/index.js";
import employeeList from "../app/employeeList/vuex/index.js";
import appraisalCriteria from "../app/appraisalCriteria/vuex/index.js";
import appraisalList from "../app/appraisalList/vuex/index.js";
import myProfile from "../app/myProfile/vuex/index.js";

export default createStore({
    state,
    getters,
    mutations,
    actions,
    modules: {
        home: home,    
        employeeList: employeeList,    
        home: home,
        appraisalCriteria: appraisalCriteria,    
        appraisalList: appraisalList,    
        myProfile: myProfile,    
    },
});