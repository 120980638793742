export const showSelectCriteria = (state, flag) => {
    state.showSelectCriteria = flag
}
export const hideSelectCriteria = (state) => {
    state.showSelectCriteria = false
}

export const showSelectEmployee = (state, flag) => {
    state.showSelectEmployee = flag
}
export const hideSelectEmployee = (state) => {
    state.showSelectEmployee = false
}

export const showDeleteModel = (state, flag) =>{
    state.showDeleteModel = flag
}
