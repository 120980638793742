import { createApp } from 'vue';
import axios from 'axios'
import VueAxios from 'vue-axios'
import router from './router';
import './index.css';
import store from './store/store.js';
import App from './App.vue';  


const app = createApp(App).use(store).use(router);
app.use(VueAxios, axios);

app.mount('#app');