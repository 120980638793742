<template>
    <div class="">
        <div class="flex items-center justify-between px-6 py-2">
            <div>
                <img src="images/Sekai_Ichi_Logo_File.png" class="w-44 h-16 object-contain">
            </div>
            <div class="block lg:hidden">
                <button type="button" class="flex items-center mr-6" @click="showNavList()">
                    <svg class="w-7 fixed z-50" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd">
                        </path></svg> 
                </button>
            </div>
            <div class="hidden lg:block">                
                <div class="flex items-center gap-4 xl:gap-8 text-sm font-semibold"> 
                    <a href="https://www.sekai-ichi.com/" class="hover:text-sky-500 hover:underline" target="_blank">Home</a>
                    <a href="https://www.sekai-ichi.com/vision.php?menulink=vision.php" class="hover:text-sky-500 hover:underline" target="_blank">Vision</a>
                    <a href="https://www.sekai-ichi.com/product.php?menulink=product.php" class="hover:text-sky-500 hover:underline" target="_blank">Products</a>
                    <a href="https://www.sekai-ichi.com/engineering-solution.php?menulink=engineering-solution.php" class="hover:text-sky-500 hover:underline" target="_blank">Services</a>
                    <a href="https://www.sekai-ichi.com/social.php?menulink=social.php" class="hover:text-sky-500 hover:underline" target="_blank">Social Responsibility</a>
                    <a href="https://www.sekai-ichi.com/about-sekai-ichi.php?menulink=about-sekai-ichi.php" class="hover:text-sky-500 hover:underline" target="_blank">About</a>
                    <a href="https://www.sekai-ichi.com/careers.php?menulink=careers.php" class="hover:text-sky-500 hover:underline" target="_blank">Careers</a>
                    <a href="https://www.sekai-ichi.com/contact.php?menulink=contact.php" class="hover:text-sky-500 hover:underline" target="_blank">Contact</a>
                    <router-link to="/" class="hover:text-sky-500 hover:underline">Login</router-link>
                </div>
            </div>
            
      </div>

        <!-- tab and mobile screen -->
        <div v-if="showMenuList">
            <div class="fixed inset-0 z-50 bg-white p-6">
                <div class="flex w-full">
                    <div class="flex flex-col space-y-4 font-semibold text-sm w-full">
                        <a href="https://www.sekai-ichi.com/" class="hover:text-sky-500 hover:underline" target="_blank">Home</a>
                        <a href="https://www.sekai-ichi.com/vision.php?menulink=vision.php" class="hover:text-sky-500 hover:underline" target="_blank">Vision</a>
                        <a href="https://www.sekai-ichi.com/product.php?menulink=product.php" class="hover:text-sky-500 hover:underline" target="_blank">Products</a>
                        <a href="https://www.sekai-ichi.com/engineering-solution.php?menulink=engineering-solution.php" class="hover:text-sky-500 hover:underline" target="_blank">Services</a>
                        <a href="https://www.sekai-ichi.com/social.php?menulink=social.php" class="hover:text-sky-500 hover:underline" target="_blank">Social Responsibility</a>
                        <a href="https://www.sekai-ichi.com/about-sekai-ichi.php?menulink=about-sekai-ichi.php" class="hover:text-sky-500 hover:underline" target="_blank">About</a>
                        <a href="https://www.sekai-ichi.com/careers.php?menulink=careers.php" class="hover:text-sky-500 hover:underline" target="_blank">Careers</a>
                        <a href="https://www.sekai-ichi.com/contact.php?menulink=contact.php" class="hover:text-sky-500 hover:underline" target="_blank">Contact</a>
                        <router-link to="/" class="hover:text-sky-500 hover:underline">Login</router-link>
                    </div>
                    <div>
                        <button class="w-10"  @click="showNavList()"> 
                            <svg xmlns="http://www.w3.org/2000/svg" height="40" width="40"><path d="m10.542 30.958-1.5-1.5 9.5-9.458-9.5-9.458 1.5-1.5 9.458 9.5 9.458-9.5 1.5 1.5-9.5 9.458 9.5 9.458-1.5 1.5-9.458-9.5Z"/></svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>

  </div>
</template>

<script>
    export default {
        data: function() {
            return {         
                showMenuList: false,
            }
        },

        methods: {
            showNavList(){
                this.showMenuList = !this.showMenuList
            },
            
        },
        mounted() {

        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
