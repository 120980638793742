export default [
	{
        path: '/home',
        component: () => import('../components/Home.vue'),
        name: 'home',
        meta: { 
                 
        }
    },
   
]