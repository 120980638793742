export default [
	{
        path: '/my-profile',
        component: () => import('../components/MyProfile.vue'),
        name: 'my-profile',
        meta: { 
        }
    },
    {
        path: '/edit-profile',
        component: () => import('../components/partial/EditProfile.vue'),
        name: 'edit-profile',
        meta: { 
        }
    },
   
]