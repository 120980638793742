export default [
	{
        path: '/appraisal-criteria',
        component: () => import('../components/AppraisalCriteria.vue'),
        name: 'appraisal-criteria',
        meta: { 
                 
        }
    },
   
]