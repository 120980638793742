export default [
	{
        path: '/',
        component: () => import('../components/Login.vue'),
        name: 'sign-in',
        meta: { 
                 
        }
    },

    {
        path: '/forgot-password',
        component: () => import('../components/ForgotPassword.vue'),
        name: 'forgot-password',
        meta: { 
                 
        }
    },
    {
        path: '/reset-password',
        component: () => import('../components/ResetPassword.vue'),
        name: 'reset-password',
        meta: { 
                 
        }
    },
]