export const getSelectManager = (state) => {	
	return state.showSelectManager
}
export const hideSelectManager = (state) => {
	return state.hideSelectManager
}
export const getDeleteModel = (state) =>{
	return state.showDeleteModel
}
// export const hideDeleteModel = (state) =>{
// 	return state.hideDeleteModel
// }
