export const getSelectCriteria = (state) => {	
	return state.showSelectCriteria
}
export const hideSelectCriteria = (state) => {
	return state.hideSelectCriteria
}


// select employ
export const getSelectEmployee = (state) => {	
	return state.showSelectEmployee
}
export const hideSelectEmployee = (state) => {
	return state.hideSelectEmployee
}

//Delete Modal
export const getDeleteModel = (state) =>{
	return state.showDeleteModel
}