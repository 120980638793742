<template>
    <div class="">
        <div>
            <Header/>            
        </div>
        <div class="flex h-96" v-if="isAuth">
            <div class="lg:block hidden" :class="[sidebarMenuClass]">
                <Sidebar class="fixed h-full" id="sidebar" />                
            </div>
            <div class="block lg:hidden">   
                <MobileSidebar />
            </div>
            <div class="w-full router-container" :class="[containerClass]" id="container-box">
                <router-view class="md:pl-2 2xl:pl-7" /> 
                <Footer class="md:pl-2 2xl:pl-7" />
            </div>
        </div>
        <div v-if="!isAuth">
            <router-view  />
            <Footer class="" />
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import Header from './components/Header.vue'
import Sidebar from './components/Sidebar.vue'
import MobileSidebar from './components/MobileSidebar.vue'
import Footer from './components/Footer.vue'

export default {
    data: function() {
    return {
        sidebarMenu: true,
        sidebarMenuClass: 'w-48 lg:w-64 lg:block hidden',
        containerClass: 'w-full lg:w-10/12 2xl:w-full',
        footerClass: 'w-full md:pl-1 xl:pl-footerPadding 2xl:ml-0',
    }
},
    components: {
        Header,
        Sidebar,
        MobileSidebar,
        Footer,
    },
    computed: mapState([
        'isAuth',
        ]),
     methods: {
     collapseSidebar: function() {
             this.sidebarMenu = !this.sidebarMenu
             if (this.sidebarMenu){
                this.sidebarMenuClass = 'w-48 lg:w-64 md:block hidden';
                this.containerClass = 'w-full md:w-10/12 2xl:w-full';
                this.footerClass = 'w-full md:pl-1 xl:pl-footerPadding 2xl:ml-0';
             }
             else {
                this.sidebarMenuClass = 'w-16 md:block hidden';
                this.containerClass = 'w-full md:w-12/12 xl:pl-2 md:px-0';                
                this.footerClass = 'w-full ';                
             }
        }       
    },

    mounted() {
        let hideScroll = window.pageYOffset;
        window.onscroll = function() {
            let currentScrollPos = window.pageYOffset;
            if (window.pageYOffset < 3 ) {

                if(document.getElementById("navbar")){
                    document.getElementById("navbar").style.top = "0";
                }
                if(document.getElementById("sidebar")){
                    document.getElementById("sidebar").style.top = "4rem";
                }
                if(document.getElementById("sidebarLg")){
                    document.getElementById("sidebarLg").style.top = "4rem";
                }
                
                if(document.getElementById("title_fixed")){
                    document.getElementById("title_fixed").style.top = "2.8rem";
                }
                if(document.getElementById("title_fixed")){
                    document.getElementById("title_fixed").style.position = "static";
                }

            } else {

                if(document.getElementById("sidebar")){
                    document.getElementById("sidebar").style.top = "0";
                }

                if(document.getElementById("sidebarLg")){
                    document.getElementById("sidebarLg").style.top = "0";
                }
                if(document.getElementById("navbar")){
                    document.getElementById("navbar").style.top = "-165px";
                }
              
                if(document.getElementById("title_fixed")){
                    document.getElementById("title_fixed").style.top = "0";
                }
                if(document.getElementById("title_fixed")){
                    document.getElementById("title_fixed").style.position = "sticky";
                }
            }
            hideScroll = currentScrollPos;
        }
    },
}
</script>

<style>
    #navbar {
      transition: top 0.3s;
  }

  #sidebar {
      transition: top 0.1s;
  }
  .router-container{    
    transition: 0.2s ease;      
  }
</style>
