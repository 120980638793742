export default [
	{
        path: '/appraisal-list',
        component: () => import('../components/AppraisalList.vue'),
        name: 'appraisal-list',
        meta: { 
                 
        }
    },
    {
        path: '/create-appraisal',
        component: () => import('../components/CreateAppraisal.vue'),
        name: 'create-appraisal',
        meta: { 
                 
        }
    },
    {
        path: '/view-appraisal',
        component: () => import('../components/partials/ViewAppraisal.vue'),
        name: 'view-appraisal',
        meta: { 
                 
        }
    },
    {
        path: '/edit-appraisal',
        component: () => import('../components/partials/EditAppraisal.vue'),
        name: 'edit-appraisal',
        meta: { 
                 
        }
    },
    
    {
        path: '/appraisal-details',
        component: () => import('../components/partials/AppraisalDetails.vue'),
        name: 'appraisal-details',
        meta: { 
                 
        }
    },
    {
        path: '/appraisal-user-detail',
        component: () => import('../components/AppraisalUserDetail.vue'),
        name: 'appraisal-user-detail',
        meta: { 
                 
        }
    },

]