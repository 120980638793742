export const showSelectManager = (state, flag) => { 
    state.showSelectManager = flag
}
export const hideSelectManager = (state) => {
    state.showSelectManager = false
}
export const showDeleteModel = (state, flag) =>{
    state.showDeleteModel = flag
}
// export const hideDeleteModel = (state, flag) =>{
//     state.showDeleteModel = false
// }