// put your main mutations here
export default {
	// setShowSidebar(state, value) {
 //  		this.state.showSidebar = value
 //  	},

 isAuthenticated(state, flag) {
		state.isAuth = flag
	},
}
