export default [
	{
        path: '/employee-list',
        component: () => import('../components/EmployeeList.vue'),
        name: 'employee-list',
        meta: { 
                 
        }
    },
   {
        path: '/add-employee',
        component: () => import('../components/partials/AddEmployee.vue'),
        name: 'add-employee',
        meta: { 
                 
        }
    },
    {
        path: '/edit-employee',
        component: () => import('../components/partials/EditEmployee.vue'),
        name: 'edit-employee',
        meta: { 
                 
        }
    },
    {
        path: '/view-employee',
        component: () => import('../components/partials/ViewEmployee.vue'),
        name: 'view-employee',
        meta: { 
                 
        }
    },
   
]