<template>
	<div>
		<div class="relative md:block hidden">
			<img src="images/Footer.png" class="w-full">
			<div class="absolute inset-0 flex items-center mx-4 xl:mx-20 gap-10">
				<div class="w-6/12 space-y-8 text-white">
					<div>
						<img src="images/Sekai_Ichi_Logo_File.png" class="w-32 xl:w-44 h-16 object-contain rounded-full">
					</div>
					<div>
						<p class="font-semibold text-xs xl:text-sm">
						SEKAI-ICHI is the company with the mission of developing an innovative Solutions for various problems in today's world. We encourage to make the things that change the way of life.
					</p>
					</div>
					<div class="text-sm font-bold">
						 We are way to smart life.
					</div>
				</div>
				<div class="w-6/12 space-y-4 mt-6 2xl:-mt-10">
					<div class="flex items-center justify-center gap-4 pl-16">
						<label>
							<svg class="fill-current w-5 xl:w-9" fill="none" viewBox="0 0 37 37">
								<path fill="#fff" d="M.192383 2.73119c0-1.42351 1.191637-2.578846 2.660747-2.578846H33.5316c1.4696 0 2.6608 1.155336 2.6608 2.578846V33.574c0 1.424-1.1912 2.5783-2.6608 2.5783H2.85313c-1.46911 0-2.660747-1.1543-2.660747-2.5779V2.73072v.00047Z"/>
								<path fill="#EF1923" d="M11.8913 30.1519V13.9584H6.59925v16.1935h5.29245-.0004Zm-2.64512-18.404c1.84512 0 2.99372-1.2436 2.99372-2.79756-.0346-1.58925-1.1486-2.798-2.95858-2.798-1.81133 0-2.99421 1.20875-2.99421 2.798 0 1.55396 1.1482 2.79756 2.92438 2.79756h.03469Zm5.57412 18.404h5.2915v-9.0421c0-.4835.0347-.968.1744-1.3132.3824-.9675 1.2531-1.9688 2.7153-1.9688 1.9145 0 2.6807 1.4848 2.6807 3.662v8.6621h5.2916v-9.2848c0-4.9738-2.6104-7.2882-6.092-7.2882-2.8546 0-4.1082 1.6226-4.8046 2.7279h.0351v-2.3479h-5.2916c.0689 1.5191-.0004 16.1934-.0004 16.1934v-.0004Z"/>
							</svg>
						</label>

						<label>
							<svg class="fill-current w-5 xl:w-9" fill="none" viewBox="0 0 37 37">
								<rect width="36" height="36" x=".192383" y=".152344" fill="#fff" rx="5"/>
								<path fill="#EF1923" d="M13.3835 18.9347h2.3871v10.8305c0 .2139.1572.3871.3512.3871h4.0474c.194 0 .3512-.1732.3512-.3871V18.9858h2.7442c.1784 0 .3286-.1476.3489-.3429l.4168-3.9873c.0115-.1097-.0201-.2195-.0867-.3018-.0666-.0824-.162-.1296-.2621-.1296h-3.1609v-2.4994c0-.7534.3681-1.1355 1.0942-1.1355h2.0667c.194 0 .3512-.1733.3512-.3871V6.54231c0-.21384-.1572-.3871-.3512-.3871h-2.8482c-.0201-.00109-.0647-.00287-.1305-.00287-.4942 0-2.212.10692-3.5689 1.48266-1.5034 1.52454-1.2944 3.3499-1.2445 3.6664v2.9228h-2.5059c-.1941 0-.3513.1732-.3513.3871v3.9363c0 .2138.1572.3871.3513.3871Z"/>
							</svg>
						</label>

						<label>
							<svg class="fill-current w-5 xl:w-9" fill="none" viewBox="0 0 37 37">
								<rect width="36" height="36" x=".192383" y=".152344" fill="#fff" rx="5"/>
								<path fill="#EF1923" d="M28.3298 25.0758c-.901-.9182-3.0914-2.3167-4.1635-2.8441-1.243-.6122-1.7189-.6122-2.5738.0424-.7301.5226-1.2015 1.05-2.061.8287-.8595-.1742-2.4907-1.488-4.1219-3.1078-1.6312-1.6622-2.8742-3.3244-3.0498-4.2002-.171-.8759.3003-1.3562.8133-2.1002.6423-.8758.6423-1.3561.0416-2.6228-.513-1.0924-1.8438-3.28199-2.7911-4.24258-.89184-.91351-1.10903-.6969-1.58037-.51797 0 0-.73011.30607-1.46022.78637-.90109.61214-1.41864 1.13953-1.76059 1.87881-.34195.78637-.7717 2.18487 1.28925 5.94717 1.67279 3.0184 3.30403 5.3351 5.70693 7.7837 2.4491 2.4956 4.681 4.1578 7.643 5.82 3.6922 2.1002 5.0646 1.7046 5.8363 1.3138.7717-.3485 1.2431-.8758 1.8438-1.7941.4713-.7439.7717-1.4879.7717-1.4879.3465-.3909.5175-.5651-.3836-1.4833Z"/>
							</svg>
						</label>
					</div>
					<div class="flex justify-center gap-8 2xl:pt-4">
						<div class="space-y-2 text-white flex flex-col">
							<label class="text-xs xl:text-md font-bold mb-2">About Us</label>
							<label class="text-xs xl:text-sm font-semibold">Bring You</label>
							<label class="text-xs xl:text-sm font-semibold">Success Factors</label>
							<label class="text-xs xl:text-sm font-semibold">Staffing Services</label>
							<label class="text-xs xl:text-sm font-semibold">Directors ProfileWe </label>
						</div>
						<div class="space-y-2 text-white flex flex-col">
							<label class="text-xs xl:text-md font-bold mb-2">Contact Us</label>
							<label class="text-xs xl:text-sm font-semibold">Factors</label>
							<label class="text-xs xl:text-sm font-semibold">Staffing</label>
							<label class="text-xs xl:text-sm font-semibold">Services</label>
							<label class="text-xs xl:text-sm font-semibold">Success</label>
						</div>
						<div class="space-y-2 text-white flex flex-col">
							<label class="text-xs xl:text-md font-bold mb-2">Products</label>
							<label class="text-xs xl:text-sm font-semibold">Smart Recruit</label>
							<label class="text-xs xl:text-sm font-semibold">Smart Planner</label>
							<label class="text-xs xl:text-md font-bold">More...</label>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- responsive mobile -->
		<div class="relative block md:hidden">
			<img src="images/footerMobile.png" class="w-full">
			<div class="absolute inset-0 mx-2 space-y-6">
				<div class="flex">
					<div class="w-6/12">
						<img src="images/Sekai_Ichi_Logo_File.png" class="w-32 xl:w-44 h-16 object-contain rounded-full">
					</div>
					<div class="w-6/12 flex items-center justify-center gap-4 mt-6">
						<label>
							<svg class="fill-current w-4" fill="none" viewBox="0 0 37 37">
								<path fill="#fff" d="M.192383 2.73119c0-1.42351 1.191637-2.578846 2.660747-2.578846H33.5316c1.4696 0 2.6608 1.155336 2.6608 2.578846V33.574c0 1.424-1.1912 2.5783-2.6608 2.5783H2.85313c-1.46911 0-2.660747-1.1543-2.660747-2.5779V2.73072v.00047Z"/>
								<path fill="#EF1923" d="M11.8913 30.1519V13.9584H6.59925v16.1935h5.29245-.0004Zm-2.64512-18.404c1.84512 0 2.99372-1.2436 2.99372-2.79756-.0346-1.58925-1.1486-2.798-2.95858-2.798-1.81133 0-2.99421 1.20875-2.99421 2.798 0 1.55396 1.1482 2.79756 2.92438 2.79756h.03469Zm5.57412 18.404h5.2915v-9.0421c0-.4835.0347-.968.1744-1.3132.3824-.9675 1.2531-1.9688 2.7153-1.9688 1.9145 0 2.6807 1.4848 2.6807 3.662v8.6621h5.2916v-9.2848c0-4.9738-2.6104-7.2882-6.092-7.2882-2.8546 0-4.1082 1.6226-4.8046 2.7279h.0351v-2.3479h-5.2916c.0689 1.5191-.0004 16.1934-.0004 16.1934v-.0004Z"/>
							</svg>
						</label>

						<label>
							<svg class="fill-current w-4" fill="none" viewBox="0 0 37 37">
								<rect width="36" height="36" x=".192383" y=".152344" fill="#fff" rx="5"/>
								<path fill="#EF1923" d="M13.3835 18.9347h2.3871v10.8305c0 .2139.1572.3871.3512.3871h4.0474c.194 0 .3512-.1732.3512-.3871V18.9858h2.7442c.1784 0 .3286-.1476.3489-.3429l.4168-3.9873c.0115-.1097-.0201-.2195-.0867-.3018-.0666-.0824-.162-.1296-.2621-.1296h-3.1609v-2.4994c0-.7534.3681-1.1355 1.0942-1.1355h2.0667c.194 0 .3512-.1733.3512-.3871V6.54231c0-.21384-.1572-.3871-.3512-.3871h-2.8482c-.0201-.00109-.0647-.00287-.1305-.00287-.4942 0-2.212.10692-3.5689 1.48266-1.5034 1.52454-1.2944 3.3499-1.2445 3.6664v2.9228h-2.5059c-.1941 0-.3513.1732-.3513.3871v3.9363c0 .2138.1572.3871.3513.3871Z"/>
							</svg>
						</label>

						<label>
							<svg class="fill-current w-4" fill="none" viewBox="0 0 37 37">
								<rect width="36" height="36" x=".192383" y=".152344" fill="#fff" rx="5"/>
								<path fill="#EF1923" d="M28.3298 25.0758c-.901-.9182-3.0914-2.3167-4.1635-2.8441-1.243-.6122-1.7189-.6122-2.5738.0424-.7301.5226-1.2015 1.05-2.061.8287-.8595-.1742-2.4907-1.488-4.1219-3.1078-1.6312-1.6622-2.8742-3.3244-3.0498-4.2002-.171-.8759.3003-1.3562.8133-2.1002.6423-.8758.6423-1.3561.0416-2.6228-.513-1.0924-1.8438-3.28199-2.7911-4.24258-.89184-.91351-1.10903-.6969-1.58037-.51797 0 0-.73011.30607-1.46022.78637-.90109.61214-1.41864 1.13953-1.76059 1.87881-.34195.78637-.7717 2.18487 1.28925 5.94717 1.67279 3.0184 3.30403 5.3351 5.70693 7.7837 2.4491 2.4956 4.681 4.1578 7.643 5.82 3.6922 2.1002 5.0646 1.7046 5.8363 1.3138.7717-.3485 1.2431-.8758 1.8438-1.7941.4713-.7439.7717-1.4879.7717-1.4879.3465-.3909.5175-.5651-.3836-1.4833Z"/>
							</svg>
						</label>
					</div>
				</div>

				<div class="text-white space-y-3">
					<div>
						<p class="font-semibold text-xs xl:text-sm">
							SEKAI-ICHI is the company with the mission of developing an innovative Solutions for various problems in today's world. We encourage to make the things that change the way of life.
						</p>
					</div>
					<div class="text-sm font-bold">
						We are way to smart life.
					</div>
				</div>

				<div class="flex justify-center gap-8 2xl:pt-4">
					<div class="space-y-2 text-white flex flex-col">
						<label class="text-xs xl:text-md font-bold mb-2">About Us</label>
						<label class="text-xs xl:text-sm font-semibold">Bring You</label>
						<label class="text-xs xl:text-sm font-semibold">Success Factors</label>
						<label class="text-xs xl:text-sm font-semibold">Staffing Services</label>
						<label class="text-xs xl:text-sm font-semibold">Directors ProfileWe </label>
					</div>
					<div class="space-y-2 text-white flex flex-col">
						<label class="text-xs xl:text-md font-bold mb-2">Contact Us</label>
						<label class="text-xs xl:text-sm font-semibold">Factors</label>
						<label class="text-xs xl:text-sm font-semibold">Staffing</label>
						<label class="text-xs xl:text-sm font-semibold">Services</label>
						<label class="text-xs xl:text-sm font-semibold">Success</label>
					</div>
					<div class="space-y-2 text-white flex flex-col">
						<label class="text-xs xl:text-md font-bold mb-2">Products</label>
						<label class="text-xs xl:text-sm font-semibold">Smart Recruit</label>
						<label class="text-xs xl:text-sm font-semibold">Smart Planner</label>
						<label class="text-xs xl:text-md font-bold">More...</label>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>